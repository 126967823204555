import { RouteRecordRaw } from 'vue-router';

const devRoutes: RouteRecordRaw[] = [
  {
    path: '/dev/ChatbotDocs',
    name: 'ChatbotDocs',
    component: () => import('@/features/Chatbot/ChatbotDocs.vue')
  },
  {
    path: '/dev/ActivityListItemEventDocs',
    name: 'ActivityListItemEventDocs',
    component: () =>
      import(
        '@/components/specific/Activity/Items/ActivityListItemEventDocs.vue'
      )
  },
  {
    path: '/dev/design-system/pf-tree-explorer',
    name: 'PfTreeExplorerDocs',
    component: () =>
      import(
        '@/components/NewDesignSystem/PfTreeExplorer/PfTreeExplorerDocs.vue'
      )
  },
  {
    path: '/dev/design-system/pf-tree-explorer-with-preferred-items',
    name: 'PfTreeExplorerWithPreferredItemsDocs',
    component: () =>
      import(
        '@/components/NewDesignSystem/PfTreeExplorerWithPreferredItems/PfTreeExplorerWithPreferredItemsDocs.vue'
      )
  },
  {
    path: '/dev/PurchaseOrdersListTableRowAmountWarningsDocs',
    name: 'PurchaseOrdersListTableRowAmountWarningsDocs',
    component: () =>
      import(
        '@/features/PurchaseOrders/List/Table/Row/PurchaseOrdersListTableRowAmountWarningsDocs.vue'
      )
  },
  {
    path: '/dev/form-requester/counterparty',
    name: 'FormRequesterStepsCounterPartyDocs',
    component: () =>
      import(
        '@/components/Intake/FormRequester/Steps/CounterParty/FormRequesterStepsCounterPartyDocs.vue'
      )
  },
  {
    path: '/dev/purchase-orders/actions-approve',
    name: 'PurchaseOrdersActionsApproveDocs',
    component: () =>
      import(
        '@/features/PurchaseOrders/Actions/PurchaseOrdersActionsApproveDocs.vue'
      )
  },
  {
    path: '/app-info',
    name: 'AppInfo',
    component: () => import('@/views/AppInfo.vue')
  },
  {
    path: '/dev/side-peek/activity-list-item',
    name: 'ActivityListItem',
    component: () =>
      import('@/components/SidePeek/ActivityListItemCounterpartyDocs.vue')
  },
  {
    path: '/features',
    name: 'Features',
    component: () => import('@/views/Features.vue')
  },
  {
    path: '/test-new-bank-identifiers',
    name: 'TestNewBankIdentifiers',
    component: () => import('@/views/TestNewBankIdentifiers.vue')
  },
  {
    path: '/test-address',
    name: 'TestAddress',
    component: () => import('@/views/TestAddress.vue')
  },
  {
    path: '/test-permissions',
    name: 'TestPermissions',
    component: () => import('@/views/TestPermissions.vue')
  },
  {
    path: '/test-send-flow',
    name: 'TestSendFlow',
    component: () => import('@/views/TestSendFlow.vue')
  },
  {
    path: '/test-tree',
    name: 'TestTree',
    component: () => import('@/views/TestTree.vue')
  },
  {
    path: '/test-unknown',
    name: 'TestUnknown',
    component: () => import('@/views/TestUnknown.vue')
  },
  {
    path: '/test-counterparty-payments-multi-select',
    name: 'TestCounterpartyMultiSelect',
    component: () => import('@/views/TestCounterpartyMultiSelect.vue')
  },
  {
    path: '/test-workflow-instance',
    name: 'TestWorkflowInstance',
    component: () => import('@/views/TestWorkflowInstance.vue')
  },
  {
    path: '/test-expected-invoices',
    name: 'TestExpectedInvoices',
    component: () => import('@/views/TestExpectedInvoices.vue')
  },
  {
    path: '/test-bank-order-transactions',
    name: 'TestBankOrderTransactions',
    component: () => import('@/views/TestBankOrderTransactions.vue')
  },
  {
    path: '/debug-tenant',
    name: 'DebugTenant',
    component: () => import('@/views/DebugTenant.vue')
  },
  {
    path: '/debug-client',
    name: 'DebugClient',
    component: () => import('@/views/DebugClient.vue')
  },
  {
    path: '/debug-clients',
    name: 'DebugClients',
    component: () => import('@/views/DebugClients.vue')
  },
  {
    path: '/debug-accounts',
    name: 'DebugAccounts',
    component: () => import('@/views/DebugAccounts.vue')
  },
  {
    path: '/test-home-activity',
    name: 'TestHomeActivity',
    component: () => import('@/views/TestHomeActivity.vue')
  },
  {
    path: '/test-account-selection-field',
    name: 'TestAccountSelectionField',
    component: () => import('@/views/TestAccountSelectionField.vue')
  },
  {
    path: '/bo-test',
    name: 'BoTest',
    component: () => import('@/views/BoTest.vue')
  },
  {
    path: '/bo-test2',
    name: 'BoTest2',
    component: () => import('@/views/BoTest2.vue')
  },
  {
    path: '/dev/test-request-scopes',
    name: 'TestRequestScopes',
    component: () => import('@/views/dev/TestRequestScopes.vue')
  }
];

export default devRoutes;

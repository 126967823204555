<template>
  <Teleport to="body">
    <div
      v-if="modelValue"
      class="ds-modal"
      v-bind="$attrs"
    >
      <div
        :class="{
          'modal-container': true,
          'modal-container--is-medium': isMedium,
          'modal-container--is-wide': isWide
        }"
      >
        <div
          ref="modalBox"
          class="modal-box"
          aria-modal="true"
          role="dialog"
        >
          <slot></slot>
          <button
            v-if="!hideQuickClose"
            type="button"
            class="close-button"
            :disabled="noQuickClose"
            @click="closeModal"
          >
            <DsIcon
              name="cross"
              color="gray400"
            />
          </button>
        </div>
      </div>
    </div>
  </Teleport>
</template>

<script lang="ts" setup>
import { onMounted, onUnmounted, ref, watch } from 'vue';

import DsIcon from './DsIcon.vue';

const props = defineProps({
  modelValue: { type: Boolean, required: true },
  isMedium: { type: Boolean, default: false },
  isWide: { type: Boolean, default: false },
  noQuickClose: { type: Boolean, default: false },
  hideQuickClose: { type: Boolean, default: false }
});

const emit = defineEmits(['update:modelValue', 'onClose']);

const modalBox = ref<HTMLDivElement>();

const handleMousedown = (event) => {
  if (modalBox.value?.contains(event.target) === false) {
    closeModal();
  }
};

const handleKeydown = (event) => {
  if (event.key === 'Escape') {
    closeModal();
  }
};

const closeModal = () => {
  emit('onClose');
  emit('update:modelValue', false);
};

watch(
  () => props.modelValue,
  (newValue) => {
    if (newValue === true) {
      if (props.noQuickClose === false) {
        window.document.addEventListener('mousedown', handleMousedown);
        window.document.addEventListener('keydown', handleKeydown);
      }
      window.document.body.classList.add('body-ds-modal-is-opened');
    } else {
      window.document.removeEventListener('mousedown', handleMousedown);
      window.document.removeEventListener('keydown', handleKeydown);
      window.document.body.classList.remove('body-ds-modal-is-opened');
    }
  }
);

onMounted(() => {
  if (props.modelValue === true) {
    if (props.noQuickClose === false) {
      window.document.addEventListener('mousedown', handleMousedown);
      window.document.addEventListener('keydown', handleKeydown);
    }
    window.document.body.classList.add('body-ds-modal-is-opened');
  }
});

onUnmounted(() => {
  window.document.removeEventListener('mousedown', handleMousedown);
  window.document.removeEventListener('keydown', handleKeydown);
  window.document.body.classList.remove('body-ds-modal-is-opened');
});

defineExpose({
  closeModal
});
</script>

<style lang="scss" scoped>
$border-radius: 24px;

.body-ds-modal-is-opened {
  overflow: hidden;
}

.ds-modal {
  position: fixed;
  z-index: 1000;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  overflow-y: auto;
  display: flex;
  align-items: safe center;
  justify-content: center;

  padding: 12px;

  background: rgba(20, 23, 37, 0.3);

  @media screen and (min-width: 769px) {
    padding: 40px;
  }
}

.modal-container {
  width: 470px;
  height: max-content;
  margin: auto;

  border-radius: $border-radius;
  box-shadow: 0 16px 24px 0 rgba($gray1000, 0.16);

  &.modal-container--is-wide {
    width: 95%;

    @media (min-width: 1024px) {
      width: 80%;
    }

    @media (min-width: 1280px) {
      width: 60%;
    }

    @media (min-width: 1920px) {
      width: 40%;
    }
  }

  &.modal-container--is-medium {
    width: 75%;

    @media (min-width: 1024px) {
      width: 60%;
    }

    @media (min-width: 1280px) {
      width: 50%;
    }

    @media (min-width: 1920px) {
      width: 30%;
    }
  }
}

.modal-box {
  position: relative;

  display: flex;
  flex-direction: column;

  min-height: 64px;

  background-color: white;
  border-radius: $border-radius;
}

.close-button {
  cursor: pointer;

  position: absolute;
  top: 24px;
  right: 24px;

  display: inline-flex;
  align-items: center;
  justify-content: center;

  background-color: white;
  border-radius: 8px;

  &:disabled {
    cursor: not-allowed;
  }
}
</style>
